import React from 'react';
// importar json
import Datos from '../local-json/contact.json';
// css
import './styles/contact-view-style.css'
// components
import ContactCard from '../components/ContactCard/ContactCard';

const ContactView = () => {

  return (
    <>
      <section className='contact'>
        <div className="container">
          <div className="row gap-4 align-items-center justify-content-center">
            {Datos.map((element, index)=>{ return <ContactCard 
              title={element.title} 
              lunesViernes={element.lunesViernes}
              sabadoDomingo={element.sabadoDomingo}
              whatsapp={element.whatsapp}
              instagram={element.instagram}
              facebook={element.facebook} 
              contact={element.contact}
              key={index} /> })}
          </div>
        </div>
      </section>
      <section className='map'>
        <div className="container">
          <div className="row align-items-center justify-content-center" >
            <iframe 
              data-aos="fade-down"
              id='club-cano-map'
              className='club-cano-map'
              title='club-cano-map'
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3350.997794516532!2d-68.86567882453599!3d-32.87177777362427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x967e0859737643a5%3A0xb5587f36213263c7!2sClub%20Social%20y%20Deportivo%20Barrio%20Cano!5e0!3m2!1ses-419!2sar!4v1731525243350!5m2!1ses-419!2sar" 
              width="800" 
              height="500" 
              style={{ border: 0 }} 
              allowFullScreen="" 
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade">
            </iframe>
          </div>
        </div>
      </section>
    </>
  )
};   

export default ContactView;          