import React from 'react';
// css
import './styles/us-view-style.css';
// img
import logoCanoNosotros from '../assets/img/nosotros/us-img.svg';
import rodrigoImage from '../assets/img/nosotros/comision/rodrigo-img.png';
// components
import ComisionCard from '../components/ComisionCard/ComisionCard';

const UsView = () => {
  return (
    <>

      <section className='nosotros'>
        <div className="container text-center">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-6 col-12 d-flex align-items-center justify-content-center" data-aos="fade-right">
              <img src={logoCanoNosotros} alt="logo club cano" className='img-fluid' />
            </div>
            <div className="col-md-6 col-12" data-aos="fade-left"> 
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing</p>
            </div>
          </div>
        </div>
      </section>

      <section className='comision'>
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-6">
              <ComisionCard name={'Rodrigo Hinojosa'} img={rodrigoImage} position={'PRESIDENTE'} />
            </div>
            <div className="col-md-3 col-6">
              <ComisionCard name={'Carlos Ferro'} img={rodrigoImage} position={'VICEPRESIDENTE'} />
            </div>
            <div className="col-md-3 col-6">
              <ComisionCard name={'Carina Fuentes'} img={rodrigoImage} position={'TESORERA'} />
            </div>
            <div className="col-md-3 col-6">
              <ComisionCard name={'Raul Tarateta'} img={rodrigoImage} position={'SECRETARIO GENERAL'} />
            </div>
          </div>
        </div>
      </section>
    
    </>
  )
};

export default UsView;