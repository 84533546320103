import React from 'react';
// img
import redArrow from '../../assets/img/hacete-socio/red-arrow.svg';
// css
import './partner-steps-style.css';
const PartnerSteps = ({ text }) => {
  return (
    <div className="col-12 d-flex align-items-center justify-content-center text-start" data-aos="fade-right">
      <img src={redArrow} alt="red arrow" className='img-fluid d-inline' style={{height:'4rem'}}/>
      <h3 className='d-inline hacete-socio__steps'> { text } </h3>  
    </div>
  );
};

export default PartnerSteps;