import React from 'react';
// css
import './styles/mainView.css';
// components
import ActivitiesCard from '../components/ActivitiesCard/ActivitiesCard';
// img
import logoCano from '../assets/img/club-cano-logo-sin-fondo-vector.svg';
import tenis from '../assets/img/activities/tenis-image-shadow.jpg';
import paddle from '../assets/img/activities/paddel-shadow.jpg';
import pileta from '../assets/img/activities/pool-image-shadow.jpg';
import gimnasio from '../assets/img/activities/gym-shadow.jpg';
import basketball from '../assets/img/activities/basketball-image-shadow.jpg';
import futsal from '../assets/img/activities/futsal-image-shadow.jpg';

const MainView = () => {
  return (
    <>
      <section className='hero d-flex align-items-center justify-content-center flex-nowrap shadow-lg'>
        <h1 className='hero__title d-inline hero__red' data-aos="zoom-in">Club <span className='hero__blue hero__span'>Cano</span></h1>
      </section>

      <section className='nosotros__main'>
        <div className="container">
          <h2 className='text-start fw-bold fs-1' data-aos="fade-down">Nuestra Historia</h2>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-sm-12 align-items-center justify-content-center d-flex" data-aos="fade-right">
              <img src={logoCano} alt="logo de club cano" className='img-fluid nosotros__logo-cano'/>
            </div>
            <div className="col-lg-6 col-sm-12 text-center" data-aos="fade-left">
              <p className="fst-italic">
              "Cómo nació el club Guillermo Cano: su historia
                El 21 de junio de 1937 se colocó la piedra fundamental del complejo habitacional “Casas Colectivas”, construido dentro de la ciudad Capital de Mendoza. Sus viviendas, en propiedad horizontal, fueron destinadas para dar en arriendo como habitación de empleados modestos, obreros, pensionados y jubilados públicos y particulares  El complejo habitacional fue inicialmente previsto para albergar entre 3.000 a 4.000 habitantes.
                Impensadamente, el 18 de marzo de 1940 llegó a Mendoza un grupo de 102 ex marinos del acorazado de bolsillo Admiral Graf Spee, otrora buque insignia de la Kriesgmarine de Alemania, marinos que en su gran mayoría fueron “internados” en el Pabellón “G” de las Casas Colectivas, edificación que en ese momento estaba en sus últimos detalles de terminación.
                Al poco tiempo, y a fin de conservar su disciplina militar, para hacer ejercicio y deporte, detrás de dicho Pabellón “G”, esos marinos construyeron un campo de deportes, rompiendo piedra, alisando, nivelando y acarreando material de relleno con cuñas, mazas, palas y picos. Después prepararon los arcos de fútbol y marcaron el terreno, armando así la aún recordada “canchita de los alemanes”. El deporte era una de las actividades predilectas de los marineros y jamás disminuyó la intensidad de su práctica, confrontando de manera habitual con equipos integrados por lugareños.
                El 10 de junio de 1951 lo que nació a la realidad fue el Club Social y Deportivo Barrio Cano, cuyo deporte más fuerte fue el básquetbol. En el local de la Escuela República de Chile, sita al ingreso de las Casas Colectivas, se celebró la asamblea de vecinos."
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="activities">

        <div className="container">
        
        <h2 className='text-start fw-bold fs-1' data-aos="fade-down">Actividades</h2>  

          <div className="row">
            <div className="col-lg-9 col-sm mt-4" data-aos="zoom-in-left">
              <ActivitiesCard title={'TENIS'} img={tenis} route={'tenis'} />
            </div>
            <div className="col-lg-3 col-sm mt-4" data-aos="zoom-in-right">
              <ActivitiesCard title={'PADDLE'} img={paddle} route={'paddle'} />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm mt-4" data-aos="zoom-in-left">
              <ActivitiesCard title={'PILETA'} img={pileta} route={'pileta'} />
            </div>
            <div className="col-lg-4 col-sm mt-4" data-aos="zoom-in">
              <ActivitiesCard title={'GIMNASIO'} img={gimnasio} route={'gimnasio'}/>
            </div>
            <div className="col-lg-4 col-sm mt-4" data-aos="zoom-in-left">
              <ActivitiesCard title={'BASKETBALL'} img={basketball} route={'basketball'}/>
            </div>
          </div>
          <div className="row">
            <div className="col col-sm mt-4" data-aos="zoom-in-right">
              <ActivitiesCard title={'FUTSAL'} img={futsal} route={'futsal'}/>
            </div>
          </div>

        </div>
      </section>
    </>
  )
};

export default MainView;