import React from 'react';
// css
import './comision-card-style.css';
const ComisionCard = ({ name, img, position }) => {
  return (
    <div className="comision__card text-center justify-content-center align-items-center flex-wrap" data-aos="fade-up">
      <img src={img} alt={name} className='comision__img' />
      <p className='comision__position d-block'> {position} </p>
      <p className='comision__name d-block'> {name} </p>
    </div>
  );
};

export default ComisionCard;