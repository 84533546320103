import React from 'react';
// react-router-dom
import { useParams } from 'react-router-dom';
// json
import Datos from '../local-json/sports.json';
// css
import './styles/sports-view-style.css';
import SportsHorary from '../components/SportsHorary/SportsHorary';

const SportsView = () => {
    
    const { sport } = useParams();
    
    let [sportFilter] = Datos.filter((deporte)=>{ return deporte.title === sport.toUpperCase() });

    let horarios = sportFilter.horary;

    return (
      <section className='sport' style={{backgroundImage: `url(${sportFilter.img})`}}>
        <div className="container">
          <div className="sport__card" data-aos="fade-up">
            <h1 className='text-start fw-bold'> {sportFilter.title} </h1>
            <div className="row align-items-start justify-content-center">
              <div className="col-12 col-sm-6">
                <p className='fs-4'> {sportFilter.description} </p>
              </div>
              <div className="col-12 col-sm-6 text-center">
                <h3 className='fs-3 fw-bolder'>Horarios</h3>
                {horarios.map((element, index)=>{ return <SportsHorary key={index} text={element.cat} /> })} 
                <h3 className='fs-3 fw-bolder'>Profesores</h3>
                <p className='fs-4'> {sportFilter.professor} </p>
              </div>
            </div>
          </div>

        </div>
      </section>
  );
};

export default SportsView;