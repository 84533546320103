import React from 'react';
// css
import './styles/partner-view-style.css';
// img
import downloadButton from '../assets/img/hacete-socio/download-button.svg';
import PartnerSteps from '../components/PartnerSteps/PartnerSteps';
const PartnerView = () => {
  return (
    <>
      <section className='hacete-socio'>
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <h1 className='hacete-socio__title' data-aos="fade-down">Inscribite</h1>
            </div>
          </div>
          <div className="row pt-5 gap-5">
            <PartnerSteps text={'1. Descarga el formulario e imprimilo'} />
            <PartnerSteps text={'2. Acercate a nuestra oficina para presentar el mismo'} />
            <PartnerSteps text={'3. Llevar DNI y la cuota de inscripcion              '} />
          </div>
        </div>
      </section>
      <section className='download-inscription'>
        <div className="container">
          <div className="row align-items-center justify-content-center text-center" data-aos="fade-up">
            <a href='./assets/files-pdf/formulario-de-inscripcion.pdf' download='formulario-de-inscripcion'> <img src={downloadButton} alt="boton de descarga" className='img-fluid' style={{height:'8rem'}} /> </a>
          </div>
        </div>
      </section>
    </>
  )
};

export default PartnerView;