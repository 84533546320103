import React from 'react';
import { NavLink } from 'react-router-dom';
// img
import instagramLogo from '../../assets/img/footer/instagram.svg';
import emailLogo from '../../assets/img/footer/email.svg';
import whatsappLogo from '../../assets/img/footer/whatsapp.svg';

const FooterSocial = () => {
  const instagramLink = 'https://www.instagram.com/_clubbarriocano/';
  const whatsappLink = './';
  const emailLink = './';
  return (
    <div className="col-md-4 col-12 mt-md-0 mt-4">
      <ul className='d-flex flex-row flex-nowrap justify-content-around align-items-center navbar-nav'>
        <li><NavLink to={instagramLink} target="_blank" className={'pointer footer__text'}> <img className='footer__social' src={instagramLogo} alt="Instagram link" /> </NavLink></li>
        <li><NavLink to={whatsappLink} target="_blank" className={'pointer footer__text'}> <img className='footer__social' src={whatsappLogo} alt="Whatsapp link" /> </NavLink></li>
        <li><NavLink to={emailLink} target="_blank" className={'pointer footer__text'}> <img className='footer__social' src={emailLogo} alt="Email link" /> </NavLink></li>
      </ul>
    </div>
  );
};

export default FooterSocial;