import './App.css';
import { useEffect } from 'react';
// react-router-dom routes
import { routes } from './routes';
import { HashRouter as BrowserRouter, Route, Routes } from 'react-router-dom';
// views
import MainView from './views/MainView';
import UsView from './views/UsView';
import ContactView from './views/ContactView';
import PartnerView from './views/PartnerView';
import SportsView from './views/SportsView';
// components
import NavbarComponent from './components/NavbarComponent/NavbarComponent';
import FooterComponent from './components/FooterComponent/FooterComponent';
import Bubble from './components/Bubble/Bubble';
// AOS animations
import Aos from 'aos';
import'aos/dist/aos.css';
function App() {
  // aos
  useEffect (()=>{
    Aos.init({ duration: 2000 });
  }, []);

  const { root, usView, contactView, partnerView, sportsView } = routes;

  return (
    <>
      <BrowserRouter>
        
        <NavbarComponent />
        
        <Routes>
          <Route exact path={root} element={<MainView/>} />
          <Route exact path={usView} element={<UsView/>} />
          <Route exact path={contactView} element={<ContactView/>} />
          <Route exact path={partnerView} element={<PartnerView/>} />
          <Route exact path={sportsView} element={<SportsView/>} />
        </Routes>
        
        <Bubble />

        <FooterComponent />
        
      </BrowserRouter>
    
    </>
  );
}

export default App;
