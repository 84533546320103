import React from 'react';
// css
import './bubble-style.css';
// react-router-dom
import { NavLink } from 'react-router-dom';
import { routes } from '../../routes'; 
const Bubble = () => {

  const { partnerView } = routes;
  const handleScrollUp = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };

  return (
    <NavLink to={partnerView} activeclassname='nav-link m-0' className='nav-link m-0'> 
      <div className="bubble d-flex align-items-center justify-content-center shadow-lg" onClick={()=>{ handleScrollUp() }}>
        <h3 className='bubble__title'>Hacete <br/> Socio!!</h3>
      </div>
    </NavLink>
  );
};

export default Bubble;