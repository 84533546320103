import React from 'react';
// css
import './footer-component-style.css';
// components
import FooterTitle from './FooterTitle';
import FooterLink from './FooterLink';
import FooterSocial from './FooterSocial.jsx';
import { NavLink } from 'react-router-dom';

const FooterComponent = () => {
  return (
    <section className='footer'>
      <div className="container">
        <FooterTitle />
        <div className="row mt-3">
          <FooterLink />
          <div className="col-md-4"></div>
          <FooterSocial />
        </div>
        <div className="row text-center mt-4">
          <div className="col-12 footer__description">Verano | Deportes | Familia</div>
          <div className="col-12 footer__description">Powered by: <NavLink target='_blank' to={'https://hadarsoftware.com.ar/'} className={'footer__hadar'}> <span className='footer__legal'>Hadar Software &copy;</span> </NavLink>  </div>
        </div>
      </div>
    </section>
  )
};

export default FooterComponent;