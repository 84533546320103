import React from 'react';
// react-router-dom
import { NavLink } from 'react-router-dom';
// css
import './contact-card-style.css';
// logos
import whatLogo from '../../assets/img/contact/whatsapp.svg';
import instLogo from '../../assets/img/contact/instagram.svg';
import faceLogo from '../../assets/img/contact/facebook.svg';

const ContactCard = ({ title, lunesViernes, sabadoDomingo, whatsapp, instagram, facebook, contact }) => {
  return (
    <div className='contact__card col-md-6 col-12' data-aos="fade-up">
      <h2 className='fw-bold'> {title} </h2>
      <div className="container">
        <div className="row align-items-center">
          
          <div className="col-12 pt-3 pb-3 contact__horary "> 
            
            <p className='fs-4 fw-medium'> De lunes a viernes: </p>
            <p className='fs-5'> {lunesViernes} </p>
            <p className='fs-4 fw-medium'> De sabado a domingo: </p>
            <p className='fs-5'> {sabadoDomingo} </p>

          </div>

          <div className="col-12 d-flex align-items-center justify-content-around">

            <NavLink to={whatsapp} target='_blank' className="link__social pt-3"> <img src={whatLogo} alt="logo instagram" className='img-fluid img__social' /> </NavLink>
            <NavLink to={instagram} target='_blank' className="link__social pt-3"> <img src={instLogo} alt="logo instagram" className='img-fluid img__social' /> </NavLink>
            <NavLink to={facebook} target='_blank' className="link__social pt-3"> <img src={faceLogo} alt="logo instagram" className='img-fluid img__social' /> </NavLink>
          
          </div>

        </div>
      </div>
    </div>
  );
};

export default ContactCard;