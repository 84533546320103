import React from 'react';
import { routes } from '../../routes';
import { NavLink } from 'react-router-dom';

const FooterLink = () => {

  const {root, usView, contactView, partnerView} = routes;

  const handleScrollUp = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };

  return (
    <div className="col-md-4 col-12">
			<ul className='navbar-nav d-flex flex-row flex-wrap justify-content-center align-items-center gap-4'>
				<li className='nav-item'> <NavLink to={root} className={'pointer footer__text'} onClick={()=>{ handleScrollUp() }}> Inicio </NavLink> </li>
				<li className='nav-item'> <NavLink to={usView} className={'pointer footer__text'} onClick={()=>{ handleScrollUp() }}> Nosotros </NavLink> </li>
				<li className='nav-item'> <NavLink to={contactView} className={'pointer footer__text'} onClick={()=>{ handleScrollUp() }}> Contacto </NavLink> </li>
				<li className='nav-item'> <NavLink to={partnerView} className={'pointer footer__text'} onClick={()=>{ handleScrollUp() }}> Hacete Socio </NavLink> </li>
			</ul> 
		</div>
  );
};

export default FooterLink;