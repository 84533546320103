import React from 'react';
// react-router-dom
import { useNavigate } from 'react-router-dom';
// css
import './activities-card-style.css';
const ActivitiesCard = ({ title, img, route }) => {
  
  const navigator = useNavigate();
  
  const handleScrollUp = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };

  const handleButton =()=>{
    navigator('/clubcano/deportes/' + route);
    handleScrollUp();
  };

  return (
    <div className='activities__img text-center d-flex align-items-center justify-content-center' style={{ backgroundImage: `url(${img})` }} onClick={()=>{handleButton()}}>
      <h3 className='activities__title'> {title} </h3>
    </div>
  );
};

export default ActivitiesCard;