import React, { useState } from "react";
// css
import './navbar-style.css';
// bootstrap-react
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
// routes
import { routes } from '../../routes';
import { Link } from "react-router-dom";
// img
import logoCano from '../../assets/img/club-cano-logo-sin-fondo-vector.svg';
function NavbarComponent() {

  const { root, usView, contactView, partnerView } = routes;
  const [expanded, setExpanded] = useState(false);
  const handleScrollUp = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    setExpanded(false)
  };

  return (
    <Navbar expand={'md'} className="bg-body-tertiary shadow-lg fixed-top" collapseOnSelect expanded={expanded}>
      <Container>
        <Navbar.Brand as={Link} to={root} onClick={()=>{handleScrollUp()}} className="navbar-brand"> <img src={logoCano} alt='Club Cano' className='img-fluid' width="90" height="80" /> </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} onClick={() => setExpanded(!expanded)}/>
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-sm`}
          aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
          placement="end"
        >
          <Offcanvas.Header closeButton onClick={() => setExpanded(!expanded)}>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`} className="nav-link navbar__titleOff" as={Link} to={root} onClick={()=>{handleScrollUp()}}>
              <span className="navbar__title-red"> CLUB </span> <span className="navbar__title-blue"> CANO </span> 
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-around flex-grow-1 pe-3 text-center navbar__bg">
              <Nav.Link as={Link} to={root} onClick={()=>{handleScrollUp()}}>Inicio</Nav.Link>
              <Nav.Link as={Link} to={usView} onClick={()=>{handleScrollUp()}}>Nosotros</Nav.Link>
              <Nav.Link as={Link} to={contactView} onClick={()=>{handleScrollUp()}}>Contacto</Nav.Link>
              <Nav.Link as={Link} to={partnerView} onClick={()=>{handleScrollUp()}}>Hacete Socio</Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;